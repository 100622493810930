import { ChangeEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

function phoneFilter(rawNumber: string): string {
  return rawNumber.replace(/(^\s*[+]\s*1|\s|\D)/g, '')
}

export function formatPhoneNumberValue(value?: string, forCursor = false): string {
  // Replace our current formatting
  let newValue = phoneFilter(value || '')

  const areaCode = newValue.substring(0, 3)
  const exchangeCode = newValue.substring(3, 6)
  const subNumber = newValue.substring(6, 10)
  // Ignore anything after 10.

  newValue = ''

  if (areaCode) {
    const close = areaCode.length == 3 && (exchangeCode.length > 0 || !forCursor) ? ')' : ''
    newValue += `(${areaCode}${close}`
  }
  if (exchangeCode) newValue += ` ${exchangeCode}`
  if (subNumber) newValue += ` - ${subNumber}`

  return newValue
}

function adjustCursor(rValue: string, oCursor: number | null): number | null {
  if (oCursor == null) return null

  return formatPhoneNumberValue(rValue.substring(0, oCursor), true).length
}

export const useFormattedPhoneInput: (
  value: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onChangeText: (value: string) => void
) => {
  formattedValue: string
  ref: RefObject<HTMLInputElement>
  handlePhoneNumberChanged: (e: ChangeEvent<HTMLInputElement>) => void
} = (value, onChange, onChangeText) => {
  const [position, setPosition] = useState<number | null>(null)
  const ref = useRef<HTMLInputElement>(null)

  const handlePhoneNumberChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event)
      // Remember where our cursor was.
      setPosition(adjustCursor(event.target.value, event.target.selectionStart))
      // Filter out all but our 10 significant numbers
      onChangeText(phoneFilter(event.target.value).substring(0, 10))
    },
    [onChange, onChangeText]
  )

  useEffect(() => {
    if (!ref.current) return

    ref.current.setSelectionRange(position, position)
  }, [value, position])

  const formattedValue = useMemo(() => {
    return formatPhoneNumberValue(value)
  }, [value])

  return { formattedValue, ref, handlePhoneNumberChanged }
}
