import React, { useCallback, useEffect, FocusEvent, useState, KeyboardEvent, ReactNode, forwardRef } from 'react'
import { FloatingPortal, size, useDismiss, useFloating, useInteractions, autoUpdate } from '@floating-ui/react'
import type { ElementProps } from '@floating-ui/react'
import debounce from 'lodash.debounce'
import cx from 'classnames'
import CoreInput from 'components/Core/CoreInput'
import { InputProps } from 'components/Core/CoreInput/Input'

type MappedPrediction = {
  label?: string
  title: string
  subtitle?: string
  value: string
}

export interface CoreComboBoxProps<T> extends InputProps {
  onChangePrediction?: (prediction: T | undefined) => void
  right?: ReactNode
  queryMethod: (query: string) => T[] | Promise<T[]>
  findMethod: (id: string) => (T | null) | Promise<T | null>
  mapPrediction: (prediction: T) => MappedPrediction
  initialPredictions?: T[]
  showPredictionsImmediately?: boolean
}

function CoreComboBoxInner<T>(
  {
    name,
    onChangePrediction,
    onBlur,
    right,
    queryMethod,
    mapPrediction,
    findMethod,
    className,
    defaultValue,
    initialPredictions,
    showPredictionsImmediately,
    ...rest
  }: CoreComboBoxProps<T>,
  ref: React.Ref<HTMLInputElement>
) {
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery, setDebouncedQuery] = useState<string>('')
  const [selected, setSelected] = useState<T | undefined>()
  const [predictions, setPredictions] = useState<T[]>(initialPredictions || [])
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [referenceWidth, setReferenceWidth] = useState<number>()
  const [keyboardHover, setKeyboardHover] = useState<number>()
  const [isMouseDown, setIsMouseDown] = useState(false)

  const { context, x, y, refs, strategy, update } = useFloating({
    open: popoverOpen,
    onOpenChange: setPopoverOpen,
    placement: 'bottom',
    middleware: [
      size({
        apply({ rects }) {
          setReferenceWidth(rects.reference.width)
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const interactions = [useDismiss(context)].filter(Boolean) as ElementProps[]

  const { getReferenceProps, getFloatingProps } = useInteractions(interactions)

  const handleChangeText = useCallback((value: string) => {
    setQuery(value)
    setSelected(undefined)
  }, [])

  const handleMouseDown = useCallback(() => {
    setIsMouseDown(true)
  }, [])

  const handleClick = useCallback(() => {
    // skip this click when it was caused by a focus event
    if (!isMouseDown) return
    setPopoverOpen((oldPopoverOpen) => !oldPopoverOpen)
  }, [isMouseDown])

  const handleFocus = useCallback(() => {
    setIsMouseDown(false)
    setPopoverOpen(true)
  }, [])

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      e.stopPropagation()
      e.preventDefault()

      setPopoverOpen(false)
      if (!selected) setQuery('')
      onBlur?.(e)
    },
    [selected, onBlur]
  )

  const handleSelectPrediction = useCallback(
    (prediction: T) => () => {
      setSelected(prediction)
      setPopoverOpen(false)
    },
    []
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (!predictions.length) return
      if (e.code === 'ArrowDown') {
        const next = keyboardHover === undefined ? 0 : Math.min(predictions.length - 1, keyboardHover + 1)
        setKeyboardHover(next)
      } else if (e.code === 'ArrowUp') {
        const next = keyboardHover === undefined ? predictions.length - 1 : Math.max(0, keyboardHover - 1)
        setKeyboardHover(next)
      } else if (e.code === 'Enter') {
        e.preventDefault()
        if (keyboardHover !== undefined) {
          handleSelectPrediction(predictions[keyboardHover])()
          setKeyboardHover(undefined)
        }
      }
    },
    [predictions, keyboardHover, handleSelectPrediction]
  )

  // setSelected based on defaultValue
  useEffect(() => {
    const _ = async () => {
      if (!defaultValue) return

      const value = await findMethod(defaultValue.toString())
      if (!value) return

      setSelected(value)
    }
    _()
  }, [defaultValue, findMethod])

  // update popover status when popoverOpen is set
  useEffect(() => {
    if (popoverOpen) update()
  }, [update, popoverOpen, referenceWidth])

  // debounced update on query changed
  useEffect(() => {
    const debouncedUpdate = debounce((value: string) => {
      setDebouncedQuery(value)
    }, 200)
    debouncedUpdate(query)

    return () => {
      debouncedUpdate.cancel()
    }
  }, [query])

  // call queryMethod on debouncedQuery changed
  useEffect(() => {
    if (debouncedQuery.length == 0 && !showPredictionsImmediately) {
      setPredictions(initialPredictions || [])
      return
    }
    const _ = async () => {
      try {
        const results = await Promise.resolve(queryMethod(debouncedQuery))
        setPredictions(results)
      } catch (e) {
        console.error(e)
      }
    }
    _()
  }, [debouncedQuery, initialPredictions, queryMethod, showPredictionsImmediately])

  // call onChangePrediction on selected changed
  useEffect(() => {
    onChangePrediction?.(selected)
  }, [selected, onChangePrediction])

  // clear query on selected changed
  useEffect(() => {
    if (selected) setQuery('')
  }, [selected])

  // clear selected on query changed
  useEffect(() => {
    if (query.length > 0) setSelected(undefined)
  }, [query.length])

  // setSelected based on rest.value, if not selected
  useEffect(() => {
    const id = rest.value
    if (!id || selected) return

    const _ = async () => {
      try {
        const result = await Promise.resolve(findMethod(id.toString()))
        if (result) setSelected(result)
      } catch (e) {
        console.error(e)
      }
    }
    _()
  }, [selected, findMethod, rest.value])

  return (
    <>
      <input name={name} type="hidden" value={selected ? mapPrediction(selected).value : ''} />
      <div
        {...getReferenceProps({
          ref: refs.setReference,
          className: cx('tw-relative', className),
        })}
      >
        <CoreInput.Text
          {...rest}
          ref={ref}
          value={selected ? mapPrediction(selected).label || mapPrediction(selected).title : query}
          onMouseDown={handleMouseDown}
          onChangeText={handleChangeText}
          onClick={handleClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        {!!right && (
          <div className="tw-absolute tw-top-1/2 tw--translate-y-1/2 tw-right-1 tw-w-11 tw-h-11 tw-flex tw-items-center tw-justify-center">
            {right}
          </div>
        )}
      </div>
      <FloatingPortal>
        {popoverOpen && (
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
                width: referenceWidth,
                zIndex: 50,
              },
              onMouseDown: (e) => {
                e.preventDefault() // Prevent the mouse down event from blurring the input
              },
            })}
          >
            <div className="tw-bg-white tw-rounded-xl tw-drop-shadow-lg">
              {predictions.map((prediction, i) => {
                const isActive = i === keyboardHover
                const mapped = mapPrediction(prediction)
                return (
                  <button
                    type="button"
                    className={cx(
                      'tw-w-full tw-flex tw-flex-col tw-p-3 tw-gap-0.5 tw-text-md tw-bg-white hover:tw-bg-neutrals-100 tw-text-left',
                      { '!tw-bg-neutrals-100': isActive }
                    )}
                    key={mapped.value}
                    onClick={handleSelectPrediction(prediction)}
                  >
                    <div className="tw-font-semibold">{mapped.title}</div>
                    {mapped.subtitle && <div className="tw-text-disabled-black">{mapped.subtitle}</div>}
                  </button>
                )
              })}
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  )
}

export default forwardRef(CoreComboBoxInner) as <T>(props: CoreComboBoxProps<T>) => React.ReactElement
