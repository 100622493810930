import { useState, useEffect } from 'react'
import { getAnalyticsConfig } from './analyticsConfig'
import { NonAppSignupType } from 'recoil/user'

export const UTM_PARAMS_SESSION_KEY = 'utm-params'
export const SEGMENT_UTM_PARAMS_SESSION_KEY = 'segment-utm-params'
export const STICKY_PROPS_KEY = 'sticky-props'

export interface UtmParams {
  utm_medium?: string
  utm_source?: string
  utm_campaign?: string
  utm_content?: string
  irclickid?: string // Honorary utm parameter. This is for our partnership with Impact.com
}

// We can't use EventTarget, since that is browser only (and we need to SSR)
// We can't use EventEmitter since that is node only
// So make a simple one ourselves.

const utmParamEventTarget = (function () {
  const listeners: Array<() => void> = []
  return {
    fire: () => listeners.forEach((listener) => listener()),
    addListener: (listener: () => void) => listeners.push(listener),
    removeListener: (listener: () => void) => {
      const index = listeners.indexOf(listener)
      listeners.splice(index, 1)
    },
  }
})()

export function useUtmParams(): UtmParams {
  const [utmParams, setUtmParams] = useState<UtmParams>({})

  useEffect(() => {
    const handleSetUtmParams = () => {
      const params = (window?.sessionStorage && window.sessionStorage.getItem(UTM_PARAMS_SESSION_KEY)) || '{}'
      const jParams = JSON.parse(params)
      setUtmParams(jParams)
    }
    utmParamEventTarget.addListener(handleSetUtmParams)

    // Set initial
    handleSetUtmParams()
    return () => {
      utmParamEventTarget.removeListener(handleSetUtmParams)
    }
  }, [])

  return utmParams
}

async function waitForObjInWindow(obj): Promise<any> {
  let attempts = 0
  const promise = new Promise((resolve) => {
    const check = () => {
      const w = window as any
      if (w[obj]) {
        resolve(w[obj])
      } else if (attempts < 5) {
        attempts += 1
        window.setTimeout(check, 300)
      } else {
        resolve(undefined)
      }
    }
    check()
  })
  return promise
}

// We can't make some calls like 'user' on analytics.js until it is
// actually loaded in (calls like track, and identify get cached before
// it is fully loaded, and emitted afterwards).
// For those spots, wait for the lib to be ready before continuing.
async function waitForAnalytics(): Promise<any> {
  const analytics = await waitForObjInWindow('analytics')
  if (analytics) {
    const success = await new Promise((resolve) => {
      let finished = false
      const complete = (success: boolean) => {
        if (finished) return
        finished = true
        clearTimeout(timer)
        resolve(success)
      }
      analytics.ready(() => complete(true))
      const timer = setTimeout(() => complete(false), 10000)
    })
    if (success) {
      // Now that it is loaded, grab the new full version and return that.
      return await waitForObjInWindow('analytics')
    } else console.warn('Failed to load analytics.js in time')
  }

  // This is the old, not ready (or undefined) analytics.
  return analytics
}

async function impactTrackConversion(conversionId: number, conversionData: Record<string, any>): Promise<void> {
  const ire = await waitForObjInWindow('ire')
  if (ire) {
    ire('trackConversion', conversionId, conversionData)
  } else {
    console.info('ire trackConversion', conversionId, conversionData)
  }
}

export interface IdentifyStub {
  id: number | string
  full_name: string
  email: string
  phone_number: string
  created_at: string

  // only users have this (not vendor users)
  entered_address?: string | null
  sha1_email?: string
  referred_by_user?: number | null
  non_app_signup_type?: NonAppSignupType

  // only vendor users have this:
  vendor_name?: string
}

export async function identify(user: IdentifyStub): Promise<void> {
  const analytics = await waitForAnalytics()
  const analyticsConfig = getAnalyticsConfig()

  // vendor portal and advisor hub are using the new "Mixpanel (Actions)" destination type
  // https://segment.com/docs/connections/destinations/catalog/actions-mixpanel/
  // In those cases, we send the attributes without a dollar sign, as segment will add it
  const traits: Record<string, string | number | boolean> =
    user.id.toString().startsWith('vendor-contact-') || user.id.toString().startsWith('operations-admin-')
      ? {
          created: user.created_at,
          email: user.email,
          name: user.full_name,
          phone: user.phone_number,
        }
      : {
          $created: user.created_at,
          $email: user.email,
          $name: user.full_name,
          $phone: user.phone_number,
        }

  if (user.entered_address) {
    traits.address = user.entered_address
  }
  if (user.sha1_email) {
    traits.sha1Email = user.sha1_email
  }
  if (user.referred_by_user) {
    traits.referredByUser = user.referred_by_user
  }
  if (user.non_app_signup_type) {
    traits.unserviceable = user.non_app_signup_type == 'unserviceable'
  }
  if (user.vendor_name) {
    traits.vendorName = user.vendor_name
  }

  const oldAnonymousId = analytics?.user()?.anonymousId()

  if (analytics && analyticsConfig.toSegment) {
    await new Promise((resolve) => analytics.identify(user.id, traits, resolve))
  }
  if (analyticsConfig.toConsole) {
    console.info('%cidentify %s %o', analyticsConfig.consoleStyle, user.id, traits)
  }

  // If we're identified with a userId, and we re-identify with another
  // userId, it will reset our anonymousId as well, and we won't be able
  // to link the events of the two accounts together logically.
  // Therefore, remember the old anonymous id, and send a custom event
  // to link the two anonymous ids together.
  if (analytics && analyticsConfig.toSegment) {
    if (oldAnonymousId && oldAnonymousId != analytics.user().anonymousId()) {
      // Send our linking event
      const options = { old_id: oldAnonymousId, new_id: analytics.user().anonymousId() }
      await new Promise((resolve) => analytics.track('anonymous_id link', options, resolve))
      // In this case, only bother sending to console it if we sent it to segment.
      if (analyticsConfig.toConsole) {
        console.info('%c%s %o', analyticsConfig.consoleStyle, 'anonymous_id link', options)
      }
    }
  }

  // gtm:
  const dataLayer = await waitForObjInWindow('dataLayer')
  dataLayer?.push({
    userId: user.id,
    userSha1Email: user.sha1_email,
    userName: user.full_name,
    userEmail: user.email,
    userPhone: user.phone_number,
    userAddress: user.entered_address,
  })
}

export async function addTraits(traits: Record<string, any>): Promise<void> {
  const analytics = await waitForObjInWindow('analytics')
  const analyticsConfig = getAnalyticsConfig()
  if (analytics && analyticsConfig.toSegment) {
    analytics.identify(traits)
  }
  if (analyticsConfig.toConsole) {
    console.info('%cidentify %o', analyticsConfig.consoleStyle, traits)
  }
}

export function addStickyProps(props: Record<string, any>, sessionOnly = false): void {
  const storage = sessionOnly ? 'sessionStorage' : 'localStorage'
  if (window?.[storage]) {
    window[storage].setItem(STICKY_PROPS_KEY, JSON.stringify({ ...getStickyProps(sessionOnly), ...props }))
  }
}

function getStickyProps(sessionOnly = false): Record<string, any> {
  const storage = sessionOnly ? 'sessionStorage' : 'localStorage'
  if (!window?.[storage]) return {}
  const sAllProps = window[storage].getItem(STICKY_PROPS_KEY)
  return (sAllProps?.length || 0) > 0 ? JSON.parse(sAllProps || '') : {}
}

const campaignKeywords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'irclickid']
// Returns an object map of the raw utm key to the raw utm value.
function utmParams(): Record<string, string> {
  const params = new URLSearchParams(location.search)
  return campaignKeywords.reduce((a, param) => {
    const value = params.get(param)
    if (value && value.length > 0) {
      a[param] = value
    }
    return a
  }, {})
}
// Returns an object map of the utm key, denoted with a "[last touch]" string,
// to the utm value with any non-word characters replaced by spaces.
function campaignParams(rawParams: Record<string, string>): Record<string, string> {
  return campaignKeywords.reduce((a, param) => {
    const value = rawParams[param]
    if (value && value.length > 0) {
      a[`${param} [last touch]`] = value
    }
    return a
  }, {})
}

export function captureUTMParams(): {
  rawParams: Record<string, string>
  params: Record<string, string>
} {
  const rawParams = utmParams()
  const params = campaignParams(rawParams)
  if (Object.keys(params).length > 0 && window?.sessionStorage) {
    window.sessionStorage.setItem(SEGMENT_UTM_PARAMS_SESSION_KEY, JSON.stringify(params))
    // Also store for general reference
    window.sessionStorage.setItem(UTM_PARAMS_SESSION_KEY, JSON.stringify(rawParams))
    utmParamEventTarget.fire()
  }
  return { rawParams, params }
}

export async function trackPage(name?: string, customParams?: Record<string, string>): Promise<void> {
  const analytics = await waitForObjInWindow('analytics')
  const analyticsConfig = getAnalyticsConfig()
  // Extract UTM params and store in session storage.
  const { params: utmParams } = captureUTMParams()

  if (Object.keys(utmParams).length > 0) {
    // Update our identity for any *fresh* utm params.
    if (analytics && analyticsConfig.toSegment) {
      analytics.identify(utmParams)
    }
    if (analyticsConfig.toConsole) {
      console.info('%cidentify %o', analyticsConfig.consoleStyle, utmParams)
    }
  }

  // Grab any UTM params from session storage
  const sPageParams = (window?.sessionStorage && window.sessionStorage.getItem(SEGMENT_UTM_PARAMS_SESSION_KEY)) || null
  const pageParams =
    (sPageParams?.length || 0) > 0
      ? JSON.parse(sPageParams || '')
      : // Fallback to fresh UTM params if session storage is empty
        // this may happen if we don't have access to session storage.
        Object.keys(utmParams).length > 0
        ? utmParams
        : undefined

  const stickyProps = getStickyProps()
  const tackyProps = getStickyProps(true)
  // Fire trackPage event.
  if (analytics && analyticsConfig.toSegment) {
    analytics.page(name || document.body.dataset.pageName, {
      ...pageParams,
      ...stickyProps,
      ...tackyProps,
      ...customParams,
    })
  }
  if (analyticsConfig.toConsole) {
    console.info('%ctrackPage %s', analyticsConfig.consoleStyle, name, {
      ...pageParams,
      ...stickyProps,
      ...tackyProps,
      ...customParams,
    })
  }
}

interface ImpactArgs {
  id: number
  args?: Record<string, any>
}
// name and args are sent to segment as-is. impactArgs useful for impact tracking
export async function track(name: string, args?: Record<string, any>, impactArgs?: ImpactArgs): Promise<void> {
  // segment:
  const analytics = await waitForObjInWindow('analytics')
  const analyticsConfig = getAnalyticsConfig()
  const stickyProps = getStickyProps()
  const tackyProps = getStickyProps(true)
  if (analytics && analyticsConfig.toSegment) {
    await new Promise((resolve) => analytics.track(name, { ...stickyProps, ...tackyProps, ...args }, resolve))
  }
  if (analyticsConfig.toConsole) {
    console.info('%c%s %o', analyticsConfig.consoleStyle, name, { ...stickyProps, ...tackyProps, ...args })
  }
  // impact: https://app.impact.com/secure/advertiser/tracking-settings/actiontracker/view-actiontracker-flow.ihtml?execution=e6s1
  if (impactArgs) {
    await impactTrackConversion(impactArgs.id, impactArgs.args || {})
  }
}
export function trackSync(name: string, args?: Record<string, any>): void {
  const analytics = window['analytics']
  const analyticsConfig = getAnalyticsConfig()
  const stickyProps = getStickyProps()
  const tackyProps = getStickyProps(true)
  if (analytics && analyticsConfig.toSegment) {
    analytics.track(name, args)
  }
  if (analyticsConfig.toConsole) {
    console.info('%c%s %o', analyticsConfig.consoleStyle, name, { ...stickyProps, ...tackyProps, ...args })
  }
}
