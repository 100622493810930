import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useState,
  ChangeEvent,
  useImperativeHandle,
} from 'react'

import cx from 'classnames'

import { formatPhoneNumberValue, useFormattedPhoneInput } from 'components/Core/useFormattedPhoneInput'
import styles from './styles.module.scss'
import { Wrapper, WrapperProps } from 'components/Core/CoreInput'

const InnerPhoneInput: ForwardRefRenderFunction<
  HTMLInputElement,
  { onChangeText?: (value: string) => void } & JSX.IntrinsicElements['input']
> = ({ onChange, onChangeText, value, ...props }, forwardedRef) => {
  const [phoneValue, setPhoneValue] = useState<string>(formatPhoneNumberValue(value ? String(value) : ''))

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
    },
    [onChange]
  )

  const handleChangedText = useCallback(
    (value: string) => {
      setPhoneValue(value)
      onChangeText?.(value)
    },
    [onChangeText]
  )

  const handlePhoneReset = useCallback(() => {
    setPhoneValue('')
    onChangeText?.('undefined')
  }, [onChangeText])

  const { formattedValue, ref, handlePhoneNumberChanged } = useFormattedPhoneInput(
    (value || phoneValue)?.toString() || '',
    handleChange,
    handleChangedText
  )

  useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement)

  return (
    <input
      ref={ref}
      className={styles.input}
      onChange={handlePhoneNumberChanged}
      onReset={handlePhoneReset}
      {...props}
      value={formattedValue}
    />
  )
}

const PhoneInput = forwardRef(InnerPhoneInput)

export type InputProps = WrapperProps & JSX.IntrinsicElements['input']

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    value,
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    id,
    label,
    placeholder,
    borderType,
    elementSize,
    showFieldRequired,
    ...props
  },
  ref
) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
      onChangeText?.(event.target.value)
    },
    [onChange, onChangeText]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      borderType={borderType}
      kind={kind}
      elementSize={elementSize}
      id={id}
      className={cx(styles.wrapper, className)}
      showFieldRequired={showFieldRequired}
    >
      {props.type == 'tel' ? (
        <PhoneInput
          id={id}
          placeholder={placeholder || label}
          value={value}
          className={styles.input}
          onChange={handleChange}
          onChangeText={onChangeText}
          {...props}
          ref={ref}
        />
      ) : (
        <input
          id={id}
          placeholder={placeholder || label}
          value={value}
          className={styles.input}
          onChange={handleChange}
          {...props}
          ref={ref}
        />
      )}
    </Wrapper>
  )
}

export default forwardRef(Input)
