import React, { FC, useCallback } from 'react'
import cx from 'classnames'
import { getInitials } from 'utils/formatting'
import { VendorContact } from 'store/vendors/types'
import CoreInput from 'components/Core/CoreInput'
import CoreButton from 'components/Core/CoreButton'
import { FancyOption } from 'components/Core/CoreInput/FancySelect'
import BaseModal from 'components/BaseModal'
import SecurityIcon from 'svgs/admin-panel-settings'
import Spinner from 'svgs/spinner'

const computeInitialVendorContactEmail = (vendorContact: VendorContact | null, vendorContacts: VendorContact[]) => {
  if (vendorContact) {
    if (!vendorContact.ooo) {
      return vendorContact.email || undefined
    } else {
      const backupContact = vendorContacts.find((e) => e.id == vendorContact.backup_vendor_contact_id)
      if (backupContact) {
        if (!backupContact.ooo) {
          return backupContact.email || undefined
        } else {
          // note we don't follow backups recursively. If first is also ooo, just pick any available as default
          const firstAvailableContact = vendorContacts.filter((e) => !e.ooo)[0]
          return firstAvailableContact ? firstAvailableContact.email || undefined : undefined
        }
      }
    }
  }
}

interface PickContactModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  vendorContacts: VendorContact[]
  vendorContact: VendorContact | null
  setVendorContact: (vendorContact: VendorContact | null) => void
  submitting: boolean
  onClose?: () => void
}

const PickContactModal: FC<PickContactModalProps> = ({
  open,
  setOpen,
  vendorContacts,
  vendorContact,
  setVendorContact,
  submitting,
  onClose,
}) => {
  const renderOption = useCallback(
    (e: FancyOption<VendorContact, string | undefined>) => {
      const backupName =
        e.payload.ooo && e.payload.backup_vendor_contact_id
          ? vendorContacts.find((x) => x.id == e.payload.backup_vendor_contact_id)?.full_name
          : null
      return (
        <div className="tw-flex tw-gap-1 tw-items-center">
          <div className="tw-shrink-0">
            <div className="tw-rounded-full tw-border-2 tw-border-neutrals-300">
              {/* {e.payload.pic_url ? (
                <img
                  alt={e.payload.full_name}
                  src={e.payload.pic_url}
                  className="tw-border-2 tw-border-white tw-rounded-full tw-size-6 tw-object-cover"
                />
              ) : ( */}
              <div className="tw-border-2 tw-border-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-pointer-events-none tw-size-6 tw-bg-green-200 tw-text-2xs tw-font-bold">
                {getInitials(e.payload.full_name)}
              </div>
              {/* )} */}
            </div>
          </div>
          <div>
            <div className={cx('tw-flex tw-gap-1 tw-items-center', { 'tw-text-disabled-black': e.disabled })}>
              <div className="tw-text-sm tw-font-semibold tw-text-left">{e.payload.full_name}</div>
              {backupName ? <div className="tw-text-sm tw-text-left">{`(Backup: ${backupName})`}</div> : null}
            </div>
            {e.payload.ooo ? (
              <div className="tw-text-xs tw-font-semibold tw-text-left tw-text-red-600">{'🏖️ Out of office'}</div>
            ) : e.payload.email ? (
              <div className="tw-text-xs tw-font-light tw-text-left">{`<${e.payload.email}>`}</div>
            ) : null}
          </div>
        </div>
      )
    },
    [vendorContacts]
  )

  const handleChangeValue = useCallback(
    (email: string) => {
      const newVendorContact = vendorContacts.find((e) => e.email == email) || null
      setVendorContact(newVendorContact)
    },
    [setVendorContact, vendorContacts]
  )

  const handleOnSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      onClose?.()
    },
    [onClose]
  )

  const initialValue = computeInitialVendorContactEmail(vendorContact, vendorContacts)

  const options: FancyOption<VendorContact, string>[] = vendorContacts.map((e) => ({
    disabled: e.ooo,
    value: e.email || '',
    payload: e,
  }))

  return (
    <BaseModal open={open} setOpen={setOpen} className="!tw-p-0">
      <header className="tw-bg-blue-100 tw-flex tw-items-center tw-justify-center tw-py-12">
        <SecurityIcon className="!tw-size-16" />
      </header>
      <div className="tw-p-6">
        <div className="tw-text-lg tw-font-bold tw-leading-tight">{'Pick a contact to perform the site visit'}</div>
        <form onSubmit={handleOnSubmit} className="tw-flex tw-flex-col tw-gap-4">
          <CoreInput.FancySelect<VendorContact, string>
            required
            disabled={submitting}
            value={initialValue}
            onChangeValue={handleChangeValue}
            options={options}
            renderOption={renderOption}
            className="tw-mt-2 tw-w-full tw-border tw-border-neutrals-700"
          />
          <CoreButton
            text="Submit"
            type="submit"
            disabled={submitting}
            icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
            className=""
          />
        </form>
      </div>
    </BaseModal>
  )
}

export default PickContactModal
