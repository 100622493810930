import React, { FC, KeyboardEvent, MouseEvent, PropsWithChildren, ReactNode, useCallback } from 'react'
import cx from 'classnames'
import Checkbox from 'components/Checkbox'
import CoreText from './CoreText'

import styles from './CoreList.module.scss'

interface CoreListWrapperProps {
  className?: string
}

const CoreListWrapper: FC<PropsWithChildren<CoreListWrapperProps>> = ({ className, children }) => {
  return <ul className={cx('tw-w-full', styles.listWrapper, className)}>{children}</ul>
}

interface CoreListItemProps {
  center?: ReactNode
  text?: string
  description?: string
  selected?: boolean
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void
  right?: ReactNode
  divider?: ReactNode
  rightCheck?: boolean
  imageSrc?: string
  leftIcon?: ReactNode
  leftIconContained?: boolean
  rightIcon?: ReactNode
  className?: string
  textClassName?: string
  size?: 'xs' | 's' | 'l'
  border?: boolean
  title?: string
}

const CoreListItem: FC<CoreListItemProps> = ({
  center,
  text,
  description,
  onClick,
  selected,
  right,
  divider,
  imageSrc,
  leftIcon,
  leftIconContained,
  rightCheck,
  rightIcon,
  className,
  textClassName,
  size = 'l',
  border,
  title,
}) => {
  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key == 'Enter' && onClick) onClick()
    },
    [onClick]
  )

  return (
    <li>
      <div
        role={onClick ? 'button' : 'listitem'}
        onClick={onClick}
        tabIndex={0}
        onKeyDown={handleKeyPress}
        className={cx(
          'tw-text-base tw-leading-5 tw-bg-white tw-w-full tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-4 tw-relative',
          styles.listItem,
          { 'tw-py-2 tw-px-6': size == 'xs', 'tw-py-3 tw-px-6': size == 's', 'tw-py-4 tw-px-6': size == 'l' },
          className
        )}
        title={title}
      >
        {border && <div className="tw-absolute tw-inset-0 tw-w-1 tw-bg-neutrals-800" />}
        {imageSrc ? (
          <img className={styles.icon} src={imageSrc} alt="" />
        ) : leftIcon ? (
          <div className={leftIconContained ? styles.iconWrapper : undefined}>{leftIcon}</div>
        ) : undefined}

        <div className={styles.details}>
          {center ? (
            center
          ) : (
            <>
              {text && (
                <CoreText.Paragraph size="l" className={textClassName}>
                  {text}
                </CoreText.Paragraph>
              )}
              {description && (
                <CoreText.Paragraph size="m" weight="light" color="tertiaryBlack" className={styles.description}>
                  {description}
                </CoreText.Paragraph>
              )}
            </>
          )}
        </div>

        {right ? (
          right
        ) : rightIcon ? (
          rightIcon
        ) : rightCheck ? (
          <Checkbox className={styles.check} checked={selected} readOnly />
        ) : null}
      </div>
      {divider}
    </li>
  )
}

const CoreList = Object.assign(CoreListWrapper, { Item: CoreListItem })

export default CoreList
