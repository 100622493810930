import React, { FC, Fragment, PropsWithChildren } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import cx from 'classnames'

interface BaseModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  width?: 'sm' | 'md' | 'xl' | '2xl' | 'full'
  className?: string
}

const BaseModal: FC<PropsWithChildren<BaseModalProps>> = ({ children, open, setOpen, width = 'xl', className }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>
        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-text-center sm:tw-items-center">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel
                className={cx(
                  'tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-p-6',
                  className,
                  {
                    'sm:tw-max-w-sm': width == 'sm',
                    'sm:tw-max-w-md': width == 'md',
                    'sm:tw-max-w-xl': width == 'xl',
                    'sm:tw-max-w-2xl': width == '2xl',
                    'sm:tw-max-w-full': width == 'full',
                  }
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default BaseModal
