import React, { KeyboardEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import cx from 'classnames'
import {
  FloatingPortal,
  FloatingFocusManager,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  autoUpdate,
  shift,
  limitShift,
} from '@floating-ui/react'
import type { Placement } from '@floating-ui/react'
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import ExpandMore from '@mui/icons-material/ExpandMore'

export type FancyOption<T, V> = {
  value: V
  disabled?: boolean
  payload: T
}

interface FancySelectProps<T, V> {
  label?: string
  options: FancyOption<T, V>[]
  renderOption?: (option: FancyOption<T, V>) => ReactNode
  onChangeValue?: (value: V) => void
  dropDownPlacement?: Placement
  className?: string
  disabled?: boolean
  name?: string
  value?: V
  // multiple?: boolean // later we can support multiple: show checks instead of radios, manage multiple values
  required?: boolean
  title?: string
}

/** If you need a native select, use <CoreInput.Select> */
function FancySelect<T, V>({
  label,
  options,
  value,
  onChangeValue,
  dropDownPlacement = 'bottom-start',
  disabled,
  renderOption,
  className,
  ...rest
}: FancySelectProps<T, V>) {
  const [open, setOpen] = useState(false)
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState<null | number>(null)
  const [minWidth, setMinWidth] = useState<null | number>(null)

  const { context, x, y, refs, strategy } = useFloating({
    open,
    onOpenChange: disabled ? undefined : setOpen,
    placement: dropDownPlacement,
    whileElementsMounted: autoUpdate,
    middleware: [
      shift({
        crossAxis: true,
        limiter: limitShift(),
        padding: 24,
      }),
    ],
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useDismiss(context)])

  useEffect(() => {
    const el = refs.domReference.current
    let observer: ResizeObserver
    if (el) {
      observer = new ResizeObserver(() => {
        setMinWidth(el.clientWidth)
      })
      observer.observe(el)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [refs])

  const handleClickOption = useCallback(
    (e) => () => {
      onChangeValue?.(e.value)
      setOpen(false)
    },
    [onChangeValue]
  )

  const findNextFocusableIndex = useCallback(
    (startIndex: number, step: number) => {
      let index = startIndex
      while (index >= 0 && index < options.length) {
        if (!options[index]?.disabled) return index
        index += step
      }
      return null
    },
    [options]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault()

      if (options.length == 0) return
      if (event.code === 'ArrowDown') {
        const newHighlightedOptionIndex = findNextFocusableIndex(
          highlightedOptionIndex == null ? 0 : highlightedOptionIndex + 1,
          1
        )
        setHighlightedOptionIndex(newHighlightedOptionIndex)
      } else if (event.code === 'ArrowUp') {
        const newHighlightedOptionIndex = findNextFocusableIndex(
          highlightedOptionIndex == null ? options.length - 1 : highlightedOptionIndex - 1,
          -1
        )
        setHighlightedOptionIndex(newHighlightedOptionIndex)
      } else if (event.code === 'Enter') {
        if (highlightedOptionIndex != null) {
          setHighlightedOptionIndex(null)
          onChangeValue?.(options[highlightedOptionIndex].value)
          setOpen(false)
        }
      } else if (event.code === 'Escape') {
        setHighlightedOptionIndex(null)
        setOpen(false)
      }
    },
    [options, findNextFocusableIndex, highlightedOptionIndex, onChangeValue]
  )

  const selectedOption = options.find((e) => e.value == value)
  const overlayStyle = {
    position: strategy,
    top: y ?? '',
    left: x ?? '',
    minWidth: minWidth != null ? `${minWidth}px` : undefined,
  }
  const buttonId = uuidv4()
  const listBoxId = uuidv4()

  return (
    <>
      {label ? (
        <label htmlFor={buttonId} className="tw-text-xs tw-text-tertiary-black">
          {label}
        </label>
      ) : null}
      <button
        type="button"
        {...getReferenceProps({ ref: refs.setReference })}
        {...rest}
        disabled={disabled}
        aria-disabled={disabled}
        aria-haspopup="listbox"
        id={buttonId}
        aria-expanded={open}
        aria-controls={listBoxId}
        className={cx('tw-flex tw-gap-2 tw-items-center tw-justify-between tw-p-2 tw-rounded-lg', className, {
          'hover:tw-bg-neutrals-100': !disabled,
          'tw-bg-neutrals-100': open,
        })}
      >
        {selectedOption ? (
          renderOption ? (
            renderOption(selectedOption)
          ) : (
            <div className="tw-font-semibold tw-text-left">{String(selectedOption.payload)}</div>
          )
        ) : (
          <div className="tw-text-disabled-black">{'Please select...'}</div>
        )}
        <ExpandMore className={cx('!tw-size-4 tw-shrink-0', { 'tw-text-disabled-black': disabled })} />
      </button>
      {open && (
        <FloatingPortal>
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({ ref: refs.setFloating, style: overlayStyle })}
              className="tw-bg-white tw-border tw-border-neutrals-200 tw-shadow-2xl tw-z-50 tw-rounded-2xl tw-p-2"
              role="listbox"
              tabIndex={0}
              onKeyDown={handleKeyDown}
              id={listBoxId}
              aria-labelledby={buttonId}
            >
              {options.map((e, i) => (
                <button
                  key={Array.isArray(e.value) ? e.value.join() : String(e.value)}
                  type="button"
                  role="option"
                  aria-selected={highlightedOptionIndex == i}
                  onClick={handleClickOption(e)}
                  disabled={e.disabled}
                  aria-disabled={disabled}
                  className={cx(
                    'tw-flex tw-gap-1 tw-items-center tw-justify-between tw-py-[6px] tw-px-2 tw-rounded-lg tw-w-full tw-outline-none',
                    {
                      'hover:tw-bg-neutrals-100 tw-cursor-default': !e.disabled,
                      'tw-cursor-not-allowed': e.disabled,
                      'tw-bg-neutrals-100': highlightedOptionIndex == i,
                    }
                  )}
                >
                  <div className="tw-grow">
                    {renderOption ? (
                      renderOption(e)
                    ) : (
                      <div className="tw-font-semibold tw-text-left">{String(e.payload)}</div>
                    )}
                  </div>
                  {value == e.value && !e.disabled ? (
                    <RadioButtonChecked className="!tw-size-4 tw-text-green-700 tw-shrink-0" />
                  ) : (
                    <RadioButtonUnchecked
                      className={cx('!tw-size-4 tw-shrink-0', {
                        'tw-text-primary-black': !e.disabled,
                        'tw-text-disabled-black': e.disabled,
                      })}
                    />
                  )}
                </button>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

export default FancySelect
