import React, { forwardRef, useRef, useImperativeHandle, useCallback, ForwardRefRenderFunction } from 'react'
import { autocompleteGooglePlaces, searchGooglePlaces } from 'apis/googlePlaces'
import CoreComboBox, { CoreComboBoxProps } from 'components/Core/CoreComboBox'

type BaseAddressProps<T> = Omit<CoreComboBoxProps<T>, 'queryMethod' | 'findMethod' | 'mapPrediction'> & {
  selectionStyle?: 'full-address' | 'first-line'
  formatValue?: (value: string) => string
}

type AddressByPlaceIdProps = BaseAddressProps<{ address: string; place_id: string }> & {
  idField: 'placeId'
}

type AddressByAddressProps = BaseAddressProps<{ address: string }> & {
  idField?: 'address'
}

type AddressProps = AddressByPlaceIdProps | AddressByAddressProps

const Address: ForwardRefRenderFunction<HTMLInputElement, AddressProps> = (
  { idField = 'address', selectionStyle = 'first-line', formatValue, ...rest },
  ref
) => {
  const internalRef = useRef<HTMLInputElement>(null)
  useImperativeHandle(ref, () => internalRef.current as HTMLInputElement, [])

  const handleFindAddress = useCallback((address: string) => {
    return { address }
  }, [])

  const handleAutocorrect = useCallback(async (query: string) => {
    const results = await autocompleteGooglePlaces(query)
    return results.items
  }, [])

  const handleSearchGooglePlaces = useCallback(async (value: string) => {
    const response = await searchGooglePlaces({ placeId: value })
    return { address: response.address, place_id: value }
  }, [])

  const mapPrediction = useCallback(
    ({ address, place_id }: { address: string; place_id?: string }) => {
      if (!address) return { title: '', subtitle: '', value: '' }
      const i = address.indexOf(',')
      const title = address.slice(0, i)
      const subtitle = address.slice(i + 2)
      let value = idField == 'address' ? address : place_id ?? ''
      if (formatValue) value = formatValue(value)

      return {
        title,
        subtitle,
        value,
        ...(selectionStyle == 'full-address'
          ? { label: address.split(', ').slice(0, -1).join(', ') }
          : { label: title }),
      }
    },
    [idField, selectionStyle, formatValue]
  )

  return idField === 'address' ? (
    <CoreComboBox<{ address: string }>
      ref={internalRef}
      queryMethod={handleAutocorrect}
      mapPrediction={mapPrediction}
      findMethod={handleFindAddress}
      {...(rest as AddressByAddressProps)}
    />
  ) : (
    <CoreComboBox<{ address: string; place_id: string }>
      ref={internalRef}
      queryMethod={handleAutocorrect}
      mapPrediction={mapPrediction}
      findMethod={handleSearchGooglePlaces}
      {...(rest as AddressByPlaceIdProps)}
    />
  )
}

export default forwardRef(Address)
