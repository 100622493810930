import { PublicPropertyData } from 'recoil/onboarding'
import { getJSON } from 'utils/fetch'

type GooglePlacesSuccessResult = {
  public_property_data: PublicPropertyData
  public_property_path: string
  unavailable: false | undefined
  marketplace: boolean
  address: string
  place_id: string
}

export type GooglePlacesUnavailableResult = {
  r_query: {
    place_id: string
    id?: string
  }
  unavailable: true
  public_property_path?: string
  suggestions: AttomTaxAssessor[]
  marketplace?: boolean
  address?: string
}

export type GooglePlacesSearchResult = { marketplace: boolean; address: string } & (
  | GooglePlacesSuccessResult
  | GooglePlacesUnavailableResult
)

type GooglePlacesQueryByAttomId = {
  placeId?: undefined
  queryString?: undefined
  attomId: string
}

type GooglePlacesQueryByPlaceId = {
  placeId: string
  queryString?: undefined
  attomId?: undefined
}

type GooglePlacesQueryByQueryString = {
  placeId?: undefined
  queryString: string
  attomId?: undefined
}

type GooglePlacesQuery = GooglePlacesQueryByAttomId | GooglePlacesQueryByPlaceId | GooglePlacesQueryByQueryString

export const searchGooglePlaces = async (
  query: GooglePlacesQuery,
  signal?: AbortSignal
): Promise<GooglePlacesSearchResult> => {
  const url = query.placeId
    ? `/google_places/search?place_id=${query.placeId}&body=1&cpp_extra=1`
    : query.attomId
      ? `/google_places/search?attom_id=${query.attomId}&body=1&cpp_extra=1`
      : `/google_places/search?${query.queryString}&body=1&cpp_extra=1`

  return await getJSON(url, { signal })
}

export type GooglePlacesAutocompleteResult = {
  items: { address: string; place_id: string }[]
}
export const autocompleteGooglePlaces = async (query: string): Promise<GooglePlacesAutocompleteResult> => {
  return await getJSON(`/google_places/autocomplete?query=${query}`)
}
